<template>
    <div style="background-color: #f4f6f9">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <ConfirmPopup></ConfirmPopup>
      <div class="col-12">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  {{ $t('customers_details') }}
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                :label="$t('back')" 
                  v-tooltip.bottom="$t('back')" 
                  @click="Goback"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="card1" class="card d-flex justify-content-center">
        <div class="col-12">
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-6" style="margin-top: 1%">
              <span class="p-float-label">
                <InputText id="name" type="text" 
                 @keypress="validateNameInput"
                                   @copy="disableAction" 
                                                @paste="disableAction" 
                                                @drop="disableAction"
                v-model="product.name" />
                <label for="name"> {{ $t('cust_name') }}</label>
              </span>
            </div>
            <div class="field col-12 md:col-6" style="margin-top: 1%">
              <span class="p-float-label">
                <InputText id="email_id" type="text" 
                @copy="disableAction" 
                                                @paste="disableAction" 
                                                @drop="disableAction"
                v-model="product.email_id" autocomplete="off"/>
                <label for="email_id">{{ $t('email') }}</label>
              </span>
            </div>
            <div class="field col-12 md:col-3" style="margin-top: 1%" v-if="!product._id">
              <span class="p-float-label">
                <InputText
                  id="password"
                  type="text"
                  v-model="product.password"
                  autocomplete="off"
                />
                <label for="password">{{ $t('password') }}</label>
              </span>
            </div>
            <div class="field col-12 md:col-3" style="margin-top: 1%" v-if="!product._id">
              <span class="p-float-label">
                <InputText
                  id="conf_password"
                  type="text"
                  v-model="product.conf_password"
                  autocomplete="off"
                />
                <label for="conf_password">{{ $t('conf_pass') }}</label>
                <div v-if="passwordError" class="text-red-500 text-sm mb-3">
                                {{ passwordError }}
                            </div>
              </span>
            </div>
            <div class="field col-12 md:col-3" style="margin-top: 1%">
              <span class="p-float-label">
                <InputText
                  id="mobile_number"
                  type="text"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  maxlength="10"
                  v-model="product.mobile_number"
                  @copy="disableAction" 
                                                @paste="disableAction" 
                                                @drop="disableAction" 
                />
                <label for="mobile_number">{{ $t('mobile') }}</label>
              </span>
            </div>
            <div class="field col-12 md:col-3" style="margin-top: 1%">
              <!-- <span class="p-float-label">
                <InputText id="Gender" type="text" v-model="product.Gender" />
                <label for="Gender">Gender</label>
              </span> -->
             
                  <div class="flex flex-wrap gap-3" style="margin-top: 3%">
                    <div class="flex align-items-center">
        <label for="gender" class=" mb-2">{{ $t('select_gen') }}</label>
    </div>
                      <div class="flex align-items-center">
                          <RadioButton v-model="product.gender" inputId="ingredient1" name="Male" value="Male" ></RadioButton>
                          <label for="ingredient1" class="ml-2">{{ $t('male') }}</label>&nbsp;
                          <RadioButton v-model="product.gender" inputId="ingredient2" name="Female" value="Female" />
                          <label for="ingredient2" class="ml-2">{{ $t('female') }}</label>
                      </div>
                      <div class="flex align-items-center">
                         
                      </div>
                      
                  </div>
       
            </div>
            <div class="field col-12 md:col-2" style="margin-top: 1%">
              <FileUpload
                class="p-button-primary"
                mode="basic"
                name="model[]"
                :auto="true"
                :custom-upload="true"
               
                :choose-label="$t('choose_image')"
                @uploader="handleFileUpload($event)"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
            </div>
            <div class="field col-12 md:col-3" style="margin-top: 1%">
              <img
                :src="product.profile_image"
                :alt="product.profile_image"
                v-if="product.profile_image"
                class="shadow-2"
                width="70"
              />
              <!-- <img
                v-else
                src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
                class="shadow-2"
                width="70"
              /> -->
            </div>
            <div class="field col-12 md:col-12">
              <h6 style="color: #6b719b; font-size: 15px; font-weight: 400">
                {{ $t('about') }} :
              </h6>
              <Editor v-model="product.about" editorStyle="height: 200px" />
            </div>
          </div>
        </div>
        <div class="col-12 md:col-12 d-flex text-center justify-content-center">
          <Button
            class="p-button-primary"
            @click="submit()"
          
            :label="$t('submit')" 
            style="
              box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
              border-color: #00b0b0 !important;
              background-color: #00b0b0 !important;
            "
          />
        </div>
      </div>
      <ScrollTop />
    </div>
  </template>
    <script>
  import apis from "@/apis";
  import axios from "axios";
  export default {
    name: "EticketCustomerCreateTicket",
    data() {
      return {
          add_edit:'Add',
        isLoadingModel: false,
        fullPage: true,
        product: [],
        passwordError:"",
        attachments: "",
        client_id: "",
        file_data: {
          filePath: "",
          contentType: "",
        },
      };
    },
    async mounted() {
      this.client_id = localStorage.getItem("client_id");
      this.client_id = "65a4f82f6177e69880ece5d6";
      var local_data = [];
      this.product = [];
      if (localStorage.getItem("CustomerDetails")) 
      {  
        local_data = await JSON.parse(localStorage.getItem("CustomerDetails"));
        this.product = local_data;
        this.attachments=this.product.profile_image;
        console.log(local_data);
        await localStorage.setItem("CustomerDetails", "");
        this.add_edit = this.currentLocale === "en" ? "Edit" : "संपादित करा";
      }else {
        this.add_edit = this.currentLocale === "en" ? "Add" : "जोडा";
  }
    },
    watch: {
      currentLocale(newLocale) {
    this.add_edit = this.product.length
      ? newLocale === "en" ? "Edit" : "संपादित करा"
      : newLocale === "en" ? "Add" : "जोडा";  
    },
  },
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
    methods: {
      validateNameInput(event) {
            const charCode = event.charCode || event.keyCode;
            if (
                (charCode >= 65 && charCode <= 90) ||  // Uppercase letters
                (charCode >= 97 && charCode <= 122) || // Lowercase letters
                charCode === 32 // Space
            ) {
                return true;
            }
            event.preventDefault(); // Prevent non-alphabet characters
        },
      disableAction(event) {
      event.preventDefault();
    },
      async submit()
      {  
        var name_regex = /^\s*$/;
        var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        var mobile_reg=/^[0-9]{10}$/;
        var password_reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
        if (this.product.name=='' || this.product.name==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail:  this.$t('pleaseEnterCustomerName'),
              life: 3000,
          });
          return false;
        }

        if (this.product.name) 
            {   
                if (name_regex.test(this.product.name)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: this.$t('error'),
                        detail: this.$t('pleaseEnterValidCustomerName'),
                        life: 3000,
                    });
                    return false;
                }                
            }

        if (this.product.email_id=='' || this.product.email_id==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('pleaseEnterEmailID'),
              life: 3000,
          });
          return false;
        }

        if (this.product.email_id) 
        {   
            if (!emailreg.test(this.product.email_id)) 
            {
                    this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                    detail: this.$t('pleaseEnterValidEmailID'),
                    life: 3000,
                });
                return false;
            }                
        }




        if ((this.product.password=='' || this.product.password==undefined) && !this.product._id) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('pleaseEnterPassword'),
              life: 3000,
          });
          return false;
        }

        

        if (this.product.password) {
          if (!password_reg.test(this.product.password)) 
            {
                    this.$toast.add({
                    severity: "error",
                    summary: this.$t('error'),
                    detail: this.$t('pleaseEnterValidPassword'),
                    life: 3000,
                });
                return false;
            } 
      }

        if ((this.product.conf_password=='' || this.product.conf_password==undefined) && !this.product._id) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('pleaseConfirmPassword'),
              life: 3000,
          });
          return false;
        }
        this.passwordError = '';


        if (this.product.password !== this.product.conf_password) {
       
        this.passwordError = this.$t('passwordMismatchError');
        return; 
        }



        if (this.product.mobile_number=='' || this.product.mobile_number==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
            
              detail : this.$t('pleaseEnterMobileNumber'),
            

              
              life: 3000,
          });
          return false;
        }
                if (/^0+$/.test(this.product.mobile_number) || /^0/.test(this.product.mobile_number)) {
          // Condition: Mobile number contains all zeros or starts with zero
          this.$toast.add({
            severity: "error",
            summary: this.$t('error'),
           
            detail : this.$t('mobileNumberInvalid'),

            life: 3000,
          });
          return false;
        }
       

        if (this.product.mobile_number) 
            {   
              if (!mobile_reg.test(this.product.mobile_number)) 
              {
                      this.$toast.add({
                      severity: "error",
                      summary: this.$t('error'),
                      detail: this.$t('pleaseEnterValidMobileNumber'),
                      life: 3000,
                  });
                  return false;
              }                
            }

        if (this.product.gender=='' || this.product.gender==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail:  this.$t('pleaseSelectGender'),
              life: 3000,
          });
          return false;
        }

        if (this.product.about=='' || this.product.about==undefined) 
        {   
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail:  this.$t('pleaseEnterAboutGuard'),
              life: 3000,
          });
          return false;
        }

          this.$confirm.require({
            target: event.currentTarget,
           // message: "Are you sure ?",
            message: this.$t('confirm_action'),
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              
              //edit
              if (this.product._id) 
              {
                  var payload = 
                  {   _id:this.product._id,
                      user_id:this.product._id,
                      client_id: localStorage.getItem("client_id"),
                      name:this.product.name,
                      email_id:this.product.email_id,
                      gender:this.product.gender,
                      mobile_number:this.product.mobile_number,
                      profile_image:this.attachments,
                      user_type:'Customer',
                      about:this.product.about,
                      password:this.product.password,
                      lang:this.$i18n.locale,
  
                  };
                      this.isLoadingModel = true;
                      var promise = apis.updateUserProfile(payload);
                      promise.then((response) => {
                      this.isLoadingModel = false;
                      if (response.data.status == true) {
                         // this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                          this.$toast.add({
                          severity: "success",
                          summary: this.$t("success"),
                          detail: response.data.message,
                          life: 3000,
                      });
              
                          this.reset_form();
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          this.Goback();
                      } else {
                          this.$toast.add({
                          severity: "error",
                          summary: "ERROR!!!",
                          detail: response.data.message,
                          life: 3000,
                          });
                      }
                      });
              
              }
              //add
              else
              {
                  var payload = 
                  {
                      user_id: localStorage.getItem("id"),
                      client_id: localStorage.getItem("client_id"),
                      name:this.product.name,
                      email_id:this.product.email_id,
                      gender:this.product.gender,
                      mobile_number:this.product.mobile_number,
                      profile_image:this.attachments,
                      user_type:'Customer',
                      about:this.product.about,
                      password:this.product.password,
                    
                      lang:this.$i18n.locale
  
                  };
                      this.isLoadingModel = true;
                      var promise = apis.regUser(payload);
                      promise.then((response) => {
                      this.isLoadingModel = false;
                      if (response.data.status == true) {
                          this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                          this.reset_form();
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          this.Goback();
                      } else {
                          this.$toast.add({
                          severity: "error",
                          summary: "ERROR!!!",
                          detail: response.data.message,
                          life: 3000,
                          });
                      }
                      });
              }
              
            
          
          
          },
            reject: () => {
              this.$toast.add({
                severity: "error",
                summary: "Rejected",
                detail: this.$t('youHaveRejected'),
                life: 3000,
              });
            },
          });
        
      },
      async Goback() {
        this.$router.push({ name: "customers" });
      },
      async reset_form() {
        this.file_data = {
          filePath: "",
          contentType: "",
        };
        this.attachments = "";
      },
      async date_time_prefix() {
          // Get the current date and time
          const currentDateTime = new Date();
          // Format the date as YYYY_MM_DD
          const formattedDate = currentDateTime
            .toISOString()
            .split("T")[0]
            .replace(/-/g, "_");
          // Format the time as HH_mm_ss
          const formattedTime = currentDateTime
            .toTimeString()
            .split(" ")[0]
            .replace(/:/g, "_");
          // Construct the final string
          const resultString = `${formattedDate}_${formattedTime}`;
          return resultString;
        },
      async handleFileUpload(event) {
        var fileUp = event.files[0];
        var file = fileUp;

        var validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('invalid_file'), 
          detail:this.$t('onlyImageFilesAllowed'),
          life: 3000,
        });
        return; // Stop the upload process
      }
        this.file = file;

        var prfeix = await this.date_time_prefix();
        var filename = prfeix + "_" + file.name;
        this.file_data = {
          filePath: filename,
          contentType: file.type,
        };
        var promise = apis.upload_to_AWS(this.file_data);
        promise.then((response) => {
          axios
            .put(response.data.data, file, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": file.type,
              },
            })
            .then((response) => {
              this.attachments =
                "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename;
                this.product.profile_image =  this.attachments;
            });
        });
      },
    },
  };
  </script>
    <style scoped>
  #card1 {
    background-color: #fff;
    border-top: 3px solid #6b719b;
  }
  .link-style {
    color: #6b719b;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
  .link-style:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  @media screen and (min-width: 992px) {
  }
  </style>